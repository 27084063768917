<template>
  <BaseDetailSimple
    :input-attributes="inputAttributes"
    :leave-guard="leaveGuard"
    :query-values="queryValues"
    :query-selects="querySelects"
    entity-type="materialSubType"
    locale-section="pages.materialSubTypes"
    field-section="materialSubTypes"
  />
</template>

<script>
import BaseDetailPage from "@/components/BaseDetailPage";

export default {
  name: "MaterialSubTypeDetailPage",
  extends: BaseDetailPage,
  data() {
    return {
      queryValues: /* GraphQL */ `
        _name: subType
        subType
        lang {
          id: language
        }
      `,
      querySelects: /* GraphQL */ `
        lang: modernLanguages {
          id: language
          sv
          en
        }
      `,
      inputAttributes: {
        subType: {
          required: true
        },
        lang: {
          required: true
        }
      }
    };
  }
};
</script>
